import { useEffect, useState } from "react"
import ToolTip from "../utils/ToolTip";

export default function TurnoInfo({ turno }) {
    const [totalMontoDigital, setTotalMontoDigital] = useState(0);
    const [totalMontoEfectivo, setTotalMontoEfectivo] = useState(0);
    const [msj, setMsj] = useState([]);
    useEffect(() => {
        const totalMonto = turno[0]?.pagos?.reduce((total, pago) => total + pago.monto, 0);
        setTotalMontoDigital(totalMonto);
        setTotalMontoEfectivo(turno[0]?.monto_efectivo);
    }, [turno])

    useEffect(() => {
        const turnoData = turno[0];
        console.log(turnoData);
        const efectivoSeparado = turnoData?.efectivo_separado;
        const pagos = turnoData?.pagos;
        if (!efectivoSeparado || !pagos) {
            setMsj([]);
            return;
        }
        const result = Object.entries(efectivoSeparado).map(([mozoCuit, efectivoValue]) => {
            const totalPagosMonto = pagos
                .filter(pago => pago.mozo_cuit === mozoCuit)
                .reduce((total, pago) => total + pago.monto, 0);

            return {
                mozo_cuit: mozoCuit,
                mozo_nombre: pagos.find(pago => pago.mozo_cuit === mozoCuit)?.mozo_nombre || "Desconocido",
                total: efectivoValue + totalPagosMonto
            };
        });
        const mensajes = result
            .filter(mozo => mozo.total !== 0)
            .map(mozo => `El mozo: ${mozo.mozo_nombre} (CUIT: ${mozo.mozo_cuit}) generó: ${mozo.total}`);
        //console.log(mensajes)
        setMsj(mensajes)
    }, [turno])
    return (
        <div className="bg-white p-6 rounded-3xl border shadow-lg max-w-md mx-auto mb-3">
            <div className="flex items-center mb-6">
                <div className="w-6 h-6 rounded-full flex items-center justify-center mr-2">
                    <ToolTip position="left" width="w-80" text={msj.length > 0 ? msj.map(mensaje => mensaje.toString()).join('\n') : "No se registro cuanto hizo cada mozo "} disabled={!msj} />
                </div>
                <h2 className="text-xl font-semibold text-gray-800">Información del turno solicitado:</h2>
            </div>

            <div className="space-y-4">
                <InfoCard
                    title="Total de Propinas"
                    amount={(totalMontoDigital + totalMontoEfectivo) || 0}
                />
                <InfoCard
                    title="Total Efectivo"
                    amount={totalMontoEfectivo || 0}
                />
                <InfoCard
                    title="Total Digital"
                    amount={totalMontoDigital || 0}
                />
            </div>
        </div>
    )
}

function InfoCard({ title, amount, icon }) {
    //const isPositive = change >= 0
    return (
        <div className="bg-gray-50 p-4 rounded-2xl">
            <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500">{title}</span>
            </div>
            <div className="flex justify-between items-end">
                <div className="flex items-center">
                    {icon}
                    <span className="text-2xl font-bold ml-2">${amount}</span>
                </div>
                {/* <span className={`text-sm ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
                    {isPositive ? '+' : ''}{change}%
                    {isPositive ? ' ↑' : ' ↓'}
                </span> */}
            </div>
        </div>
    )
}